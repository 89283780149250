import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import _ from 'lodash';
import Layout from '../layout';
import SEO from '../components/SEO';
import PostCardList from '../components/postCardLists/postCardList';
import PostPagination from '../components/postPagination';
import SubscribeForm from '../components/subscribe/SubscribeForm';
import { HeaderMedium } from '../components/headers';

const Category = ({ pageContext, data }) => {
  const postEdges = data.allMarkdownRemark.edges;
  const { category, currentPage, numPages } = pageContext;
  const postList = [];
  postEdges.forEach(edge => {
    postList.push({
      path: edge.node.fields.slug,
      tags: edge.node.frontmatter.tags,
      category: edge.node.frontmatter.category,
      cover: edge.node.frontmatter.cover,
      title: edge.node.frontmatter.title,
      date: edge.node.frontmatter.date,
      timeToRead: edge.node.timeToRead,
      excerpt: edge.node.excerpt,
    });
  });
  const path = `/categories/${_.kebabCase(category)}`;
  return (
      <Layout>
        <SEO title={`Posts about category-${category}`} path={path} />
        <div className="card border-0 text-center">
          <div className="card-header border-0 text-uppercase text-primary">{`${category}`}</div>
          <HeaderMedium title={`${category}`} />
        </div>
        <div className="row p-0 justify-content-center m-0">
          <div className="col-md-12 px-0 pt-0">
            <PostCardList posts={postList} />
            <div className="col-md-12 text-center align-self-center py-4">
              <PostPagination currentPage={currentPage} numPages={numPages} subpath={path} />
            </div>
          </div>
        </div>
        <hr />
        <div className="col-md-9 mx-auto p-4">
          <SubscribeForm />
        </div>
      </Layout>
  );
};

Category.propTypes = {
  pageContext: PropTypes.shape({
    category: PropTypes.string,
    currentPage: PropTypes.number,
    numPages: PropTypes.number,
  }).isRequired,
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }).isRequired,
            excerpt: PropTypes.string.isRequired,
            timeToRead: PropTypes.number.isRequired,
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
              tags: PropTypes.arrayOf(PropTypes.string),
              cover: PropTypes.object,
              category: PropTypes.string,
              date: PropTypes.string,
            }).isRequired,
          }).isRequired,
        }),
      ).isRequired,
    }).isRequired,
  }).isRequired,
};

export default Category;

export const pageQuery = graphql`
  query categoryQuery($category: String, $skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      limit: $limit
      skip: $skip
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { category: { eq: $category } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            cover {
              childImageSharp {
                fluid(maxWidth: 380, maxHeight: 275) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            date(formatString: "MMMM DD YYYY")
            category
          }
        }
      }
    }
  }
`;
